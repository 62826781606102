import React from 'react'
import { useSelector } from "react-redux"


function MiniView() {

    const { miniView } = useSelector(store => store.packageAssemblyReducer)


    return(
        <div className="row" >
        <div className="col-md-6" style={{"height": "40vh"}} >
            <img src={miniView.image} alt="" style={{"maxWidth": "100%", "height": "100%"}}/>
        </div>
        <div className="col-md-6">
            <h3 className="title">{`${miniView.title}`}</h3>
            {/* <div className="pull-right">
                <small>Link: </small> <Link to={`/`}>{`${item.ean}`}</Link>
            </div> */}
            {miniView.scannedQuantity !== "alles" ? (
            <h5 className="title">
                {`${miniView.scannedQuantity}/${miniView.quantity}`}
            </h5>)
            : ""}
        </div>
    </div>
    )
}

export default MiniView