import React from 'react';


// Components
import LogIn from "../../components/log-in/log-in-manager.js"


function LogInPage () {

	return (
		<LogIn />
	)
}

export default LogInPage