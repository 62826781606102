import React from 'react';


// Components
import PackageAssemblyManager from "../../components/package-assembly/package-assembly-manager.js"


function PackageAssemblyPage () {

	return (
		<PackageAssemblyManager />
	)
};

export default PackageAssemblyPage
