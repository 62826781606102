import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// Apollo
import { useQuery } from "@apollo/react-hooks"
import { CACHED_IS_LOGGED_IN } from "../graphql/queries.js"

// Pages
import PackageAssemblyPage from '../pages/package-assembly/package-assembly-page.js'
import LogInPage from '../pages/log-in/log-in-page'


function Routes() {

    const { data } = useQuery(CACHED_IS_LOGGED_IN)

    return (
        <div>
            {data.isLoggedIn ? (
            <Switch>
                <Route exact path='/' component={PackageAssemblyPage} />
                <Route exact path='/versand' component={PackageAssemblyPage} />
                <Route exact path='/scan' component={PackageAssemblyPage} />
                <Route exact path='/login' component={LogInPage} />
            </Switch>
            ) : (
            <Switch>
                <Route path='' component={LogInPage} />
            </Switch>
            )}
        </div>
    )
}

export default withRouter(Routes);