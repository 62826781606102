import React from 'react'

import { Link } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'


export default function Sidebar ({toggleSidebarMinify, toggleMobileSidebar, pageSidebarTransparent}) {

	return (
		<div>
			<div id="sidebar" className={'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '')}>
				<PerfectScrollbar className="height-full" options={{suppressScrollX: true}}>
					<Link to="/" className="sidebar-minify-btn" onClick={toggleSidebarMinify}>
						<i className="fa fa-angle-double-left"></i>
					</Link>
				</PerfectScrollbar>
			</div>
			<div className="sidebar-bg"></div>
			<div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar}></div>
		</div>
	)

}
