import React from 'react'

import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useApolloClient } from "@apollo/react-hooks"

import { USER_DATA, AUTH_TOKEN } from '../../config/constants'
import backgroundUrl from '../../assets/img/login/barcode.jpg'
// import { GET_TOKEN } from "../../graphql/queries.js"
import loginLogo from '../../assets/img/login/logo.png'


export default function LogIn () {

	const { register, handleSubmit } = useForm();
	const history = useHistory()

	const client = useApolloClient()

	// const [updateToken, { loading, error }] = useMutation(GET_TOKEN)

	function handleLogIn({username, remember=false}) {

		// updateToken({ variables: { id: username, password } })
		// .then(({ data }) => {
		// 	localStorage.setItem(AUTH_TOKEN, data.tokenAuth.token)
		// 	remember && localStorage.setItem(REMEMBER_ME, true)
		// 	client.writeData({ data: { isLoggedIn: true } })
		// 	history.push('/')
		// })
		// .catch(error => {
		// 	
		// })

		let user = {
			id: 0,
			firstName: username,
			lastName: "",
			email: "",
		}

		remember && localStorage.setItem(AUTH_TOKEN, "secretPassword")
		remember && localStorage.setItem(USER_DATA, JSON.stringify(user))

		client.writeData({ data: {
			isLoggedIn: true,
			currentUser: user
		} })
		
		history.push('/')

	}

	return (
		<div style={{"display": "block"}}>
			<div className="login-cover">
				<div className="login-cover-image" style={{ backgroundImage: 'url(' + backgroundUrl + ')'}}></div>
				<div className="login-cover-bg"></div>
			</div>
			<div className="login login-v2">
				<div className="login-header">
					<div className="brand">
						<img src={loginLogo} alt="logo" style={{"height": "100px"}}/> <b>B</b>arcode scanner
						<small> </small>
						<small style={{color: "#db5856"}}>{ }</small>
					</div>
					{/* <div className="icon">
						<i className="fa fa-lock"></i>
					</div> */}
				</div>
				<form onSubmit={handleSubmit(handleLogIn)}>
				<div className="login-content">
					
						{/* Username */}
						<div className="form-group m-b-20">
							<input
								name="username"
								placeholder="Vorname"
								ref={register({ required: true })}
								type="text"
								className="form-control form-control-lg" 
							/>
						</div>

						{/* <div className="form-group m-b-20">
							<input
								name="password"
								ref={register({ required: true })}
								type="password"
								className="form-control form-control-lg"
							/>
						</div> */}

						{/* {error? error : ''} */}

						{/* Log In button */}
						<div className="login-buttons">
							<button
								className="btn btn-success btn-block btn-lg"
								type="submit"
							>
								Log in
								{/* {loading ? "..." : "Enter" } */}
							</button>
						</div>
					
						{/* Remember me */}
						<div className="checkbox checkbox-css m-b-20">
							<input
								type="checkbox"
								id="cssCheckbox1"
								name="remember"
								ref={register}
								value={true}
								defaultChecked
							/>
							<label htmlFor="cssCheckbox1">Remember me</label>
						</div>

				</div>
				</form>
				
			</div>
		</div>
	)
}
