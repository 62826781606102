const settings = {
	pageHeader: true,
	pageheaderMegaMenu: false,
	pageHeaderLanguageBar: false,
	
	pageSidebar: false,
	pageSidebarWide: false,
	pageSidebarLight: true,
	pageSidebarMinify: false,
	pageSidebarToggled: false,
	pageSidebarTransparent: false,
	
	pageFloatSubMenuActive: false,
	pageFloatSubMenu: '',
	pageFloatSubMenuTop: 'auto',
	pageFloatSubMenuLeft: 'auto',
	pageFloatSubMenuBottom: 'auto',
	pageFloatSubMenuLineTop: 'auto',
	pageFloatSubMenuLineBottom: 'auto',
	pageFloatSubMenuArrowTop: 'auto',
	pageFloatSubMenuArrowBottom: 'auto',
	pageFloatSubMenuOffset: '',
	
	pageContent: true,
	pageContentClass: '',
	pageContentFullHeight: false,
	pageContentFullWidth: false,
	pageContentInverseMode: false,
	
	pageFooter: true,
	
	pageTopMenu: false,
	pageMobileTopMenu: false,
	
	pageTwoSidebar: false,
	
	pageRightSidebar: false,
	pageRightSidebarToggled: true,
	pageMobileRightSidebarToggled: false,
}

export default settings