import React from 'react'
import { useSelector } from "react-redux"

// Components
import Item from "./item"


export default function Items({ items, showTitle, showItems }) {


	if (items) {
		return (
            <table className="table table-striped">
                { showTitle && (<thead>
                    <tr>
                        <td>
                            Bild
                        </td>
                        <td>
                            Menge
                        </td>
                        <td>
                            Titel
                        </td>
                        <td>
                            SKU
                        </td>
                        <td>
                            Lagerplatz
                        </td>
                        <td>
                            Barcode
                        </td>
                    </tr>
                </thead>)}

                { showItems && (<tbody>
                    {items.map((item, index) => <Item key={index} item={item} />)}
                </tbody>)}
            </table>
		)
    }
        
    else {
        return(<div></div>)
    }
}