import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"

// Components
import ScannerManager from "./scanner-manager"
import MessageBox from "./message-box"
import MiniView from "./mini-view"
import Order from "./order"
import ConfigBar from "./config-bar"
import ErrorModal from "./error-modal"


function PackageAssemblyManager() {

	const { miniView, errorModal } = useSelector(store => store.packageAssemblyReducer)
    const dispatch = useDispatch()

    const refreshScanner = (e) => {
        dispatch({type: 'INPUT_TEXT', data: e.key})
    }

    const refreshScannerRemove = (e) => {
        dispatch({type: 'INPUT_TEXT_REMOVE', data: e.key})
    }

    useEffect(() => {

        document.addEventListener('keydown', refreshScanner)
        document.addEventListener('keyup', refreshScannerRemove)
    
        return () => {
            document.removeEventListener('keydown', refreshScanner)
            document.removeEventListener('keyup', refreshScannerRemove)
        }
        
    }, [])


	return (
		<div>

			{errorModal && (
				<div>
					< ErrorModal />
				</div>
			)}

			<div className="row m-b-10" style={{"height": "40vh"}}>
				<div className="col-md-5">
					< ScannerManager />
				</div>

				{miniView && (
					<div className="col-md-7" >
						< MiniView />
					</div>
				)}
			</div>

			<div className="row m-b-10">
				< MessageBox />
			</div>

			<div>
				< Order />
			</div>

			< ConfigBar />
		</div>
)
}

export default PackageAssemblyManager