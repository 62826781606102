/* eslint-disable */
// this is an auto generated file. This will be overwritten

import { gql } from "apollo-boost";


export const GET_TOKEN = gql`
    mutation TokenAuth($id: String!, $password: String!) {
        tokenAuth(username: $id, password: $password) {
            token
        }
    }
`

export const CURRENT_USER = gql`
query currentUser {
    currentUser {
        number: id
        # id
        firstName
        lastName
        email
    }
  }
`

export const SHOPIFY_TRANSLATE_PRODUCT = gql`
{
  shop {
  products(first: 5) {
      edges {
        node {
          id
          handle
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
}
`

// CACHED
export const CACHED_IS_LOGGED_IN = gql`
query IsLoggedIn {
  isLoggedIn @client
}
`

export const CACHED_CURRENT_USER = gql`
query currentUser {
    currentUser @client {
        id
        firstName
        lastName
        email
    }
  }
`



