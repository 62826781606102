import React from 'react'

import AutoLogOut from "./auto-log-out"

// Apollo
import { useQuery } from "@apollo/react-hooks"
import { CACHED_IS_LOGGED_IN } from "../../graphql/queries.js"


function AutoLogOutManager() {

    const { data } = useQuery(CACHED_IS_LOGGED_IN)

    if (data.isLoggedIn) return(
        < AutoLogOut />
    )

    return(
        <div></div>
    )
}

export default AutoLogOutManager