import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import axios from 'axios'

// Apollo
import { useQuery } from "@apollo/react-hooks"
import { CACHED_CURRENT_USER } from "../../graphql/queries.js"

// Constants
import { BACKURI } from "../../config/constants"
import { completeOrderUrl } from "../../config/constants"

// Utils
import { completeItemList, getProductId, fitWeightPattern, getProviderId,
	noNullBarcodeList, combineItems, isOrder, getBundle } from "../utils/functions"

var bgcolor_normal = "#d9e0e7" //light bllue
var bgcolor_switzerland = "#fdc7dc" //light pink



export default function Scanner() {


	const { location } = useHistory()

	const { shippingAddress, errorMessage, number, items, textInput, billbeeOrderNumber,
        state } =
		useSelector(store => store.packageAssemblyReducer)
	const dispatch = useDispatch()

    const { data, loading, error } = useQuery(CACHED_CURRENT_USER)

    const styleInput = {
        "backgroundColor": "white",
    }

	const getNewOrder = (orderCode) => {

		// Display loading gif
		dispatch({type: 'MINI_VIEW', data: {
			code: 2
        }})
        
		axios.get(`${BACKURI}order/${orderCode}/${data.currentUser.firstName}`.replace('#', 'X'))
		.then(({ data }) => {

			// Bestellung existiert nicht
			if (data.ErrorCode === 1) {
				dispatch({type: 'ERROR', data: {
				errorCode: 1
				}})
            }

			// Bestellung ist nicht bezahlt
			if (data.ErrorCode === 4) {
				dispatch({type: 'ERROR', data: {
				errorCode: 4
				}})
            }
            
			// Bestellung schon versendet?
			if (data.ErrorCode === 5) {
				dispatch({type: 'ERROR', data: {
				errorCode: 5
				}})
            }
            
			// Request errors
			if (data.ErrorCode === 7) {
				dispatch({type: 'ERROR', data: {
					errorCode: 7,
					ErrorMessage: data.ErrorMessage,
				}})
			}

			// Bestellung schon versendet?
			if (data.ErrorCode === 8) {
				dispatch({type: 'ERROR', data: {
				errorCode: 8
				}})
			}

			// No intentional error codes on backend
			if (data.ErrorCode === undefined) {
				if (location.pathname === completeOrderUrl) {
                    // Complete all quantities on items
                    data.items = completeItemList(data.items)
                    
				} else {
					// Decorators
                    data.items = noNullBarcodeList(data.items)
                    data.items = combineItems(data.items)


				}
				dispatch({type: 'NEW_ORDER', data})
			}
		})
		.catch(error => {
			dispatch({type: 'ERROR', data: {
				errorCode: 1
			}})
		})
	}

    useEffect(() => {

        // filter "Enter" keyword
        let txt = textInput.replace("Enter", '').toUpperCase()

        // decode characters used in barcodes
        if (txt.length === 21) { //fix Amazon order numbers, encoded in barcode as 304ß6373131ß7881161 instead of 304-6373131-7881161
            if (txt.substring(3,5) === "SS" && txt.substring(12,14) === "SS") { //text received as 'SS' instead of 'ß'
                txt = txt.replace('SS', '-') 
                txt = txt.replace('SS', '-') //have to replace 2 occurances (replace() only replaces first occurance)
            }
        }
        txt = txt.replace('§', '#') //fix Shopterra order numbers, encoded in barcode as §1027 instead of #1027

		function checkBarCode(item) {
			return item.ean === txt
		}

		if (textInput.includes("Enter")) {

            // isOrder?
            if (isOrder(txt)) {
                getNewOrder(txt)
            }

            // isWeightInput?
            else if (state === "weight") {
                
                if (fitWeightPattern(txt) && items && items.filter(item => item.scannedQuantity !== item.quantity).length === 0) {

                    // Display printing gif
                    dispatch({type: 'MINI_VIEW', data: {
                        code: 3
                    }})

                    getProductId(shippingAddress.Country)
                    .then((data1) => {

                        let requestData = {
                            number: number,
                            billbeeOrderNumber,
                            weight: Math.round(parseFloat(txt.replace(",", ".")) * 1000),
                            providerId: getProviderId(shippingAddress.Country),
                            productId: data1,
                            username: data.currentUser.firstName,
                        }

                        axios.post(`${BACKURI}shipping`, requestData)
                        .then(({ data }) => {
                            
                            // Request errors
                            if (data.ErrorCode === 7) {
                                dispatch({type: 'ERROR', data: {
                                    errorCode: 7,
                                    ErrorMessage: data.ErrorMessage,
                                }})
                            }

                            // Label existiert bereits
                            if (data.ErrorCode === 6) {
                                dispatch({type: 'ERROR', data: {
                                errorCode: 6
                                }})
                            } 
                            if (data.ErrorCode === 0) {
                                // Clean for next order
                                dispatch({type: 'MINI_VIEW', data: {
                                    code: 4
                                }})
                            }
                        })
                        .catch(error => {
                            // Label konnte nicht erstellt werden
                            dispatch({type: 'ERROR', data: {
                                errorCode: 9,
                            }})
                        })
                    })
                } else {

                    // Wrong weight input
                    dispatch({type: 'ERROR', data: {
                        errorCode: 12,
                    }})
                }
            }

            // Barcode? BundledItem?
            else if (items && state === "scan") {

                // Search for item by barcode
				var item = items.find(checkBarCode)
                if (item) {
					let newScannedQuantity = 1
					if (item.scannedQuantity) {
						newScannedQuantity = item.scannedQuantity + 1
					}

					// Check if sum is bigger than maximum
					if (newScannedQuantity > item.quantity) {
						// IF bigger, display error code 2

						dispatch({type: 'ERROR', data: {
							errorCode: 2
						}})

					} else {
						dispatch({type: 'ADD_SCANNED_QUANTITY', data: {
							item,
							newScannedQuantity,
						}})
					}
				}
                
                else {
                    // IF Barcode not found in current order

                    // Check if it's a bundle
                    let bundle = getBundle(txt)
					if (bundle) {

						// Check if all quantities fit the order
						let toAdd = []
						bundle.products.forEach(unBundled => {
							item = items.find(i => i.ean === unBundled.ean)
							if (item) {
								// check if quantity fits
								let newScannedQuantity = unBundled.quantity
								if (item.scannedQuantity) {
									newScannedQuantity = item.scannedQuantity + unBundled.quantity
								}

								if (newScannedQuantity > item.quantity) {
									// IF bigger, display error code 2
									dispatch({type: 'ERROR', data: {
										errorCode: 2
									}})
									return("")
								} else {
									// item to add (if no other errors forward) to the list
									let dict = {}
									dict["item"] = item
									dict["newScannedQuantity"] = newScannedQuantity
									toAdd.push(dict)
								}

							} else {
								// no item in order
								dispatch({type: 'ERROR', data: {
									errorCode: 3
								}})
								return("")
							}
						})
						
						toAdd.forEach(itemToAdd => {
							// add quantities to items
							dispatch({type: 'ADD_SCANNED_QUANTITY', data: {
								item: itemToAdd.item,
								newScannedQuantity: itemToAdd.newScannedQuantity,
							}})

						})
					}
                    
                    else {
                        // Barcode not found in order, and is not a bundle
						dispatch({type: 'ERROR', data: {
							errorCode: 3
						}})
					}

				// ELSE, add as last item scanned and +1 in quantity
				}

			}
            
            else {
                // Text not recognized
                dispatch({type: 'ERROR', data: {
                    errorCode: 13,
                }})
            }

			// Clean textInput
			dispatch({type: 'INPUT_TEXT', data: "EraseAll"})
		}

        // No enter
    }, [textInput])

    //change background color to alert for orders being shipped to Switzerland via Exporto
    if (number && !number.startsWith("EU") && shippingAddress && shippingAddress.Country === "CH") { //exclude EOS EU orders shipped on behalf of EO Supplies Germany GmbH
        document.body.style.backgroundColor = bgcolor_switzerland;
    } else {
        document.body.style.backgroundColor = bgcolor_normal;
    }
    
    
    if (error) return("Error... check scanner.js")
	if (loading) return("...")
	//if (data === undefined | data === null) return("UNDEFINED DATA") //TODO: should this be a bitwise operation (or should "||" be used insteadl of "|")?
    if (!data) return ("UNDEFINED DATA")

    if (state === "scan") return(
        // Scan state result

        <div className="row m-b-10">
            <div className="col-md-9">
                <input type="textarea" className={`form-control ${errorMessage ? "is-invalid": ""}`} 
                    value={textInput} readOnly placeholder="Scannen!" style={styleInput} />
                {errorMessage ? (<div className="invalid-feedback">{errorMessage}</div>): ""}
            </div>
            <div className="col-md-3"></div>
        </div>
    )
    
    if (state === "weight") return(
        // Weight state result
        
        <div className="input-group mb-3">
                <input type="textarea" className={`form-control ${errorMessage ? "is-invalid": ""} col-md-6`} 
                    value={textInput} readOnly placeholder="Gewicht (Kg)" style={styleInput} />
                <div className="col-md-3 input-group-prepend">
                    <button type="button" className="btn btn-green dropdown-toggle no-caret"
                        onClick={() => dispatch({type: 'INPUT_TEXT', data: "Enter"})}>
                        Versenden
                    </button>
                </div>
                {errorMessage ? (<div className="invalid-feedback">{errorMessage}</div>): ""}
        </div> 

    )
    
    return(<div>Unknown State!</div>)

}
