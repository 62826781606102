import React from 'react'

// Routing
import Routes from '../../config/routes'


export default function Content ({pageContentFullWidth, pageContentClass, pageContentInverseMode}) {
  
		return (
				<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
					<Routes />
				</div>
		)
}
