import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import App from './app.js'
import * as serviceWorker from './serviceWorker'
import rootReducer from "./rootReducer.js"

// Constants
import { AUTH_TOKEN, USER_DATA } from "./config/constants.js"

// Apollo
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

// Functions
import { saveBundledProducts } from "./components/utils/functions"

// CSS
import './index.css'

// ========================================
const httpLink = createHttpLink({
  uri: "nouri"
})

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id || null,
  addTypename: false
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // "Content-Type": "application/graphql",
      // "X-Shopify-Access-Token": "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" //localStorage.getItem(SHOPIFY_PASSWORD),
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

cache.writeData({
  data: {
    isLoggedIn: !!localStorage.getItem(AUTH_TOKEN),
    currentUser: !!localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : `{}`,
  },
})

// Save configs
saveBundledProducts()

const store = createStore(rootReducer)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
