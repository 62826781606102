import axios from 'axios'

// Constants
import { BACKURI, BUNDLED_PRODUCTS } from "../../config/constants"

const countries = { //source: https://www.html-code-generator.com/javascript/json/country-names (can choose German or other language translations)
    "AF": "Afghanistan",
    "AX": "Aland Islands",
    "AL": "Albanien",
    "DZ": "Algerien",
    "AS": "Amerikanischen Samoa-Inseln",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarktis",
    "AG": "Antigua und Barbuda",
    "AR": "Argentinien",
    "AM": "Armenien",
    "AW": "Aruba",
    "AU": "Australien",
    "AT": "Österreich",
    "AZ": "Aserbaidschan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesch",
    "BB": "Barbados",
    "BY": "Weißrussland",
    "BE": "Belgien",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivien",
    "BQ": "Bonaire, Sint Eustatius und Saba",
    "BA": "Bosnien und Herzegowina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brasilien",
    "IO": "Britisches Territorium des Indischen Ozeans",
    "BN": "Brunei Darussalam",
    "BG": "Bulgarien",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Kambodscha",
    "CM": "Kamerun",
    "CA": "Kanada",
    "CV": "Kap Verde",
    "KY": "Cayman Inseln",
    "CF": "Zentralafrikanische Republik",
    "TD": "Tschad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Weihnachtsinsel",
    "CC": "Kokosinseln (Keelinginseln)",
    "CO": "Kolumbien",
    "KM": "Komoren",
    "CG": "Kongo",
    "CD": "Kongo, Demokratische Republik Kongo",
    "CK": "Cookinseln",
    "CR": "Costa Rica",
    "CI": "Elfenbeinküste",
    "HR": "Kroatien",
    "CU": "Kuba",
    "CW": "Curacao",
    "CY": "Zypern",
    "CZ": "Tschechien",
    "DK": "Dänemark",
    "DJ": "Dschibuti",
    "DM": "Dominica",
    "DO": "Dominikanische Republik",
    "EC": "Ecuador",
    "EG": "Ägypten",
    "SV": "El Salvador",
    "GQ": "Äquatorialguinea",
    "ER": "Eritrea",
    "EE": "Estland",
    "ET": "Äthiopien",
    "FK": "Falklandinseln (Malvinas)",
    "FO": "Färöer Inseln",
    "FJ": "Fidschi",
    "FI": "Finnland",
    "FR": "Frankreich",
    "GF": "Französisch-Guayana",
    "PF": "Französisch Polynesien",
    "TF": "Südfranzösische Territorien",
    "GA": "Gabun",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Deutschland",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Griechenland",
    "GL": "Grönland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island und McDonald Islands",
    "VA": "Heiliger Stuhl (Staat der Vatikanstadt)",
    "HN": "Honduras",
    "HK": "Hongkong",
    "HU": "Ungarn",
    "IS": "Island",
    "IN": "Indien",
    "ID": "Indonesien",
    "IR": "Iran, Islamische Republik",
    "IQ": "Irak",
    "IE": "Irland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italien",
    "JM": "Jamaika",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordanien",
    "KZ": "Kasachstan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Korea, Demokratische Volksrepublik",
    "KR": "Korea, Republik von",
    "XK": "Kosovo",
    "KW": "Kuwait",
    "KG": "Kirgisistan",
    "LA": "Demokratische Volksrepublik Laos",
    "LV": "Lettland",
    "LB": "Libanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyscher arabischer Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Litauen",
    "LU": "Luxemburg",
    "MO": "Macao",
    "MK": "Mazedonien, die ehemalige jugoslawische Republik",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Malediven",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshallinseln",
    "MQ": "Martinique",
    "MR": "Mauretanien",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexiko",
    "FM": "Mikronesien, Föderierte Staaten von",
    "MD": "Moldawien, Republik",
    "MC": "Monaco",
    "MN": "Mongolei",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Marokko",
    "MZ": "Mosambik",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Niederlande",
    "AN": "Niederländische Antillen",
    "NC": "Neu-Kaledonien",
    "NZ": "Neuseeland",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolkinsel",
    "MP": "Nördliche Marianneninseln",
    "NO": "Norwegen",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Besetzte palästinensische Gebiete",
    "PA": "Panama",
    "PG": "Papua Neu-Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippinen",
    "PN": "Pitcairn",
    "PL": "Polen",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Katar",
    "RE": "Wiedervereinigung",
    "RO": "Rumänien",
    "RU": "Russische Föderation",
    "RW": "Ruanda",
    "BL": "Heiliger Barthelemy",
    "SH": "Heilige Helena",
    "KN": "St. Kitts und Nevis",
    "LC": "St. Lucia",
    "MF": "Sankt Martin",
    "PM": "Saint Pierre und Miquelon",
    "VC": "St. Vincent und die Grenadinen",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome und Principe",
    "SA": "Saudi-Arabien",
    "SN": "Senegal",
    "RS": "Serbien",
    "CS": "Serbien und Montenegro",
    "SC": "Seychellen",
    "SL": "Sierra Leone",
    "SG": "Singapur",
    "SX": "St. Martin",
    "SK": "Slowakei",
    "SI": "Slowenien",
    "SB": "Salomon-Inseln",
    "SO": "Somalia",
    "ZA": "Südafrika",
    "GS": "Süd-Georgien und die südlichen Sandwich-Inseln",
    "SS": "Südsudan",
    "ES": "Spanien",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Spitzbergen und Jan Mayen",
    "SZ": "Swasiland",
    "SE": "Schweden",
    "CH": "Schweiz",
    "SY": "Syrische Arabische Republik",
    "TW": "Taiwan, Provinz Chinas",
    "TJ": "Tadschikistan",
    "TZ": "Tansania, Vereinigte Republik",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Gehen",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad und Tobago",
    "TN": "Tunesien",
    "TR": "Truthahn",
    "TM": "Turkmenistan",
    "TC": "Turks- und Caicosinseln",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "Vereinigte Arabische Emirate",
    "GB": "Vereinigtes Königreich",
    "US": "Vereinigte Staaten",
    "UM": "Kleinere abgelegene Inseln der Vereinigten Staaten",
    "UY": "Uruguay",
    "UZ": "Usbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Virgin Inseln, Britisch",
    "VI": "Jungferninseln, USA",
    "WF": "Wallis und Futuna",
    "EH": "Westsahara",
    "YE": "Jemen",
    "ZM": "Sambia",
    "ZW": "Zimbabwe"
}

const EUCountries = {
    "AT": "Österreich",
    "BE": "Belgien",
    "BG": "Bulgarien",
    "HR": "Kroatien",
    "CY": "Zypern",
    "CZ": "Tschechien",
    "DK": "Dänemark",
    "EE": "Estland",
    "FI": "Finnland",
    "FR": "Frankreich",
    "DE": "Deutschland",
    "GR": "Griechenland",
    "HU": "Ungarn",
    "IE": "Irland",
    "IT": "Italien",
    "LV": "Lettland",
    "LT": "Litauen",
    "LU": "Luxemburg",
    "MT": "Malta",
    "NL": "Niederlande",
    "PL": "Polen",
    "PT": "Portugal",
    "RO": "Rumänien",
    "SK": "Slowakei",
    "SI": "Slowenien",
    "ES": "Spanien",
    "SE": "Schweden"
}

export const fullCountry = (code) => {
    var countrycode = code.toUpperCase()
    var country = (countries[countrycode]) ? countries[countrycode] : code
    return country
}

export const EUCountry = (code) => {
    var countrycode = code.toUpperCase()
    var country = (EUCountries[countrycode]) ? EUCountries[countrycode] : code
    return country
}

function isInt(value) {
  var x = parseFloat(value)
  return !isNaN(value) && (x | 0) === x
}

export const isOrder = (text) => {
    var char = text.substring(0,1) //first character
    var char2 = text.substring(0,2) //first 2 characters
      
    if (char === "A") { //A22767 - A99999, A22767.1, A22767.Z, A22767.E2
        return (
      	   (text.length >= 6 && text.length <= 10) //between 6 and 10 characters long
           && isInt(text.substring(1,6)) //2nd to 6th characters is an integer
           && (text.length === 6 || (text.length > 6 && text.substring(6,7) === ".")) //if greater than 6 chars, 7th char is a "."
        )
    }
    if (char === "B") { //B210104, B210104.1, B210104.Z, B210104.E2
        if (char2 === "BE") { //manual EO Supplies Germany / EOS Fulfilment orders, e.g. BE0319
            return (
           	    (text.length >= 6 && text.length <= 10) //between 6 and 10 characters long
                && (text.length === 6 || (text.length > 6 && text.substring(6,7) === ".")) //if greater than 6 chars, 7th char is a ".", e.g. BE0319.Z
                && isInt(text.substring(2,6))
            )
        }
        if (char2 === "B2") { //Aromazeug UG manual orders, e.g. B220319
           	return (
           	    (text.length >= 7 && text.length <= 11) //between 7 and 11 characters long
                && (text.length === 7 || (text.length > 7 && text.substring(7,8) === ".")) //if greater than 7 chars, 8th char is a ".", e.g. B220319.E2
                && isInt(text.substring(1,7))
            )
        }
    }
    if (char === "E" || char === "#") {

        if (char2 === "EU") { //EU1004, EU1004.1, EU1004.Z, EU1004.E2, EU12352, EU12352.Z, EU12352.E2, ...   <-- EOS EU orders
            return (
                (text.length >= 6 && text.length <= 11) //between 6 and 11 characters long
                && ( (text.length >= 6 && isInt(text.substring(2,6))) //e.g EU1235
                  || (text.length >= 7 && isInt(text.substring(2,7))) //e.g. EU12352
                )
                && ( (text.length === 6 || text.length === 7)
                  || ( (text.length > 6 && text.substring(6,7) === ".") //e.g. EU1235.Z
                    || (text.length > 7 && text.substring(7,8) === ".") //e.g. EU12352.Z
                  )
                )
             )
        } 

        //E1004, E1004.1, E1004.Z, E1004.E2, E12352, E12352.Z, E12352.E2, #1003, ... <-- old EOS DE order numbers
      	return (
            (text.length >= 5 && text.length <= 9) //between 5 and 9 characters long
            && ( (text.length >= 5 && isInt(text.substring(1,5))) //e.g E1235
              || (text.length >= 6 && isInt(text.substring(1,6))) //e.g. E12352
            )
            && ( (text.length === 5 || text.length === 6)
              || ( (text.length > 5 && text.substring(5,6) === ".") //e.g. E1235.Z
                || (text.length > 6 && text.substring(6,7) === ".") //e.g. E12352.Z
              )
            )
         )
    }
    if (char2 === "DE") { //DE1004, DE1004.1, DE1004.Z, DE1004.E2, DE12352, DE12352.Z, DE12352.E2, ...  <-- new EOS DE order numbers
        return (
            (text.length >= 6 && text.length <= 10) //between 5 and 9 characters long
            && ( (text.length >= 6 && isInt(text.substring(2,6))) //e.g DE1235
              || (text.length >= 7 && isInt(text.substring(2,7))) //e.g. DE12352
            )
            && ( (text.length === 6 || text.length === 7)
              || ( (text.length > 6 && text.substring(6,7) === ".") //e.g. DE1235.Z
                || (text.length > 7 && text.substring(7,8) === ".") //e.g. DE12352.Z
              )
            )
         )
    } 
    
    if (text.length === 19) { //Amazon 303-0232647-4618702
	    return (
        	isInt(text.substring(0,3)) && isInt(text.substring(4,11)) && isInt(text.substring(12,19))
            && (text.substring(3,4) === "-" && text.substring(11,12) === "-")
        )
    }
    return false

    /*
    if (text.match(/^[0-9]{3}-[0-9]{7}-[0-9]{6}$/) ||
        (text.match(/^(A|E|#)[0-9]{4}$/) && text.length === 5) ||
        (text.match(/^(A|E|#)[0-9]{5}$/) && text.length === 6) ||
        (text.match(/^[0-9]{4}$/) && text.length === 4) ||
        (text.match(/^[0-9]{5}$/) && text.length === 6) ||
        text.match(/^A?E?#?[0-9]{4,5}\.*$/) ||
        (text.match(/^B21+/) && text.length >= 7 && text.length <= 9)
        ) {
        return(true)
    }
    */
}

export function getProviderId(code) {
    return 11771
}

export function getProductId(code) {

    return axios.get(`${BACKURI}shipping/product_ids`)
    .then(({ data }) => {
        return data[code] ? data[code] : data["OTHERWISE"]
    })
    .catch(error => {
        return "Error in endpoint /shipping/product_ids"
    })
}

export function completeItemList(items) {
    return items.map(item => item.scannedQuantity = item.quantity)
}

export function randomString(n=7) {
    return Math.random().toString(36).substring(n)
}

export function noNullBarcodeList(items) {
    return items.map(item => {
        if (["", null, 'null', undefined].includes(item.ean)) {
            item.ean = `***** ${item.sku} *****`
        }
        return item
    })
}

export function combineItems(items) {

    let combinedItems = []

    items.forEach(item => {
        // Serch if item with same barcode already exists in combinedItems
        let isAlready = combinedItems.filter(combinedItem => combinedItem.ean === item.ean)[0]

        if (isAlready) {
            // Same SKU?
            if (isAlready.sku === item.sku) {
                // Equal barcode AND sku - Combine
                isAlready.quantity += item.quantity

            } else {
                // Equal barcode, different SKU - add random characters to barcode
                item.ean = `***${randomString()} ${item.ean}`
                combinedItems.push(item)

            }

        } else {
            // No item with equal barcode
            combinedItems.push(item)
        }

    })
    
    return combinedItems
}

export function shippingName(shippingAddress) {

    let hasFirstName = shippingAddress.FirstName !== "" && shippingAddress.FirstName !== null && shippingAddress.FirstName !== undefined
    let hasLastName = shippingAddress.LastName !== "" && shippingAddress.LastName !== null && shippingAddress.LastName !== undefined
    let hasCompany = shippingAddress.Company !== "" && shippingAddress.Company !== null && shippingAddress.Company !== undefined

    let displayName = ""
    
    if (hasCompany) {
        displayName += `${shippingAddress.Company}`

        if (hasFirstName || hasLastName) {
            displayName += " - "
        }
    }

    if (hasFirstName) {
        displayName += `${shippingAddress.FirstName} `
    }

    if (hasLastName) {
        displayName += `${shippingAddress.LastName}`
    }

    return displayName

}

export function saveBundledProducts() {

    axios.get(`${BACKURI}bundledProducts`)
    .then(({ data }) => {
        localStorage.setItem(BUNDLED_PRODUCTS, JSON.stringify(data))
    })
    .catch(error => {
    })
}

export function getBundle(scannedCode) {
    let bundles = JSON.parse(localStorage.getItem(BUNDLED_PRODUCTS))
    return bundles.find(b => b.ean === scannedCode)
}

export function fitWeightPattern(text) {

    // Match custom pattern
    let match = text.match(/^[0-9]*[,.][0-9]*$/)

    // substring matched is the full string (if not matches anything after the matched substring)
    if (match &&
        parseFloat(text.replace(",", ".")) < 31.5 &&
        parseFloat(text.replace(",", ".")) >= 0) {
            return true
        }
}
