import React, {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux"

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function ErrorModal() {

    const { errorModal, errorMessage } = useSelector(store => store.packageAssemblyReducer)
    const dispatch = useDispatch()

    const useStyles = makeStyles(theme => ({
      modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      },
      paper: {
          position: 'absolute',
          width: 450,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
      },
  }))

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)


  const handleClose = () => {
    dispatch({type: 'ERROR_MODAL', data: false})
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title"> {errorMessage ? errorMessage : "Etwas stimmt nicht" } </h2>
      <p id="simple-modal-description">
        Klicken Sie auf 'OK' oder drücken Sie 'Esc' um fortzufahren
        <div className="d-flex justify-content-center" style={{"marginTop":"25px"}}>
            <button type="button" className="btn btn-success " onClick={handleClose} >
                [ OK ]
            </button>
        </div>
      </p>
    </div>
  )

  useEffect(() => {
    const onKeyDown = (e) => {
      e.keyCode === 32 && handleClose()
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
        document.removeEventListener('keydown', onKeyDown)
    }
    
    }, [])

  return (
    <div>
      <Modal
        open={errorModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}