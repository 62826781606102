import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"

// Components
import Items from "./items"


export default function Order() {

	const { items } = useSelector(store => store.packageAssemblyReducer)
	const dispatch = useDispatch()

	useEffect(() => {
		if (items && items.filter(item => item.scannedQuantity !== item.quantity).length === 0) {
            // Order Completed
			dispatch({type: 'MINI_VIEW', data: {
				code: 1
			}})
		}
	}, [items])

	if (items) {
		return (
			<div>

                {/* Pending */}
                < Items 
                    items={items.filter(item => item.scannedQuantity !== item.quantity)}
                    showTitle={items.filter(item => item.scannedQuantity !== item.quantity).length > 0}
                    showItems={true}
                />

                {/* Completed */}
                < Items
                    items={items.filter(item => item.scannedQuantity === item.quantity)}
                    showTitle={items.filter(item => item.scannedQuantity !== item.quantity).length === 0}
                    showItems={true}
                />
                
			</div>
		)} else {
			return(<div></div>)
		}
}