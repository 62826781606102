import React, { useState, useEffect } from 'react'

import AutoLogOutManager from "./components/log-in/auto-log-out-manager"
import Header from './components/header/header'
import Sidebar from './components/sidebar/sidebar'
// import TopMenu from './components/top-menu/top-menu'
import Content from './components/content/content'
import Footer from './components/footer/footer'
// import FloatSubMenu from './components/float-sub-menu/float-sub-menu'

import settings from "./config/settings"


export default function App () {

	const [, setHasScroll] = useState(false)

	function handleScroll () {
		if (window.scrollY > 0) {
			setHasScroll(true)
		} else {
			setHasScroll(false)
		}
	}

	useEffect(() => {

		window.addEventListener('scroll', handleScroll)

		return(
			window.removeEventListener('scroll', handleScroll)
		)

	}, [])


	return (
		<div>
			<div style={{
				"minHeight": "100%",
				// "height": "auto !important",
				"height": "100%",
				"margin": "0 auto -142px", /* the bottom margin is the negative value of the footer's height */
			}} className={
				'fade page-sidebar-fixed show page-container ' + 
				(settings.pageHeader ? 'page-header-fixed ' : '') + 
				(settings.pageSidebar ? '' : 'page-without-sidebar ') + 
				(settings.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
				(settings.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
				(settings.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
				(settings.pageSidebarMinify ? 'page-sidebar-minified ' : '') + 
				(settings.pageSidebarToggled ? 'page-sidebar-toggled ' : '') + 
				(settings.pageTopMenu ? 'page-with-top-menu ' : '') + 
				(settings.pageContentFullHeight ? 'page-content-full-height ' : '') + 
				(settings.pageTwoSidebar ? 'page-with-two-sidebar ' : '') + 
				(settings.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') + 
				(settings.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') + 
				(settings.hasScroll ? 'has-scroll ' : '')
			} >
				< AutoLogOutManager />
				{settings.pageHeader && (<Header />)}
				{settings.pageSidebar && (<Sidebar settings={
					settings.toggleSidebarMinify,
					settings.toggleMobileSidebar,
					settings.pageSidebarTransparent
				} />)}
				{/* {settings.pageTopMenu && (<TopMenu settings={pageMobileTopMenu} />)} */}
				{settings.pageContent && (<Content settings={
					settings.pageContentFullWidth,
					settings.pageContentClass,
					settings.pageContentInverseMode
				} />)}
				<div style={{"height": "142px"}}></div>
			</div>
			<div style={{"height": "142px"}}>
				{settings.pageFooter && (<Footer />)}
			</div>
		</div>
	)
}
