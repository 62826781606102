// Constants
import { imageCongratulationsUrl, imageOrderNotFoundUrl, imageMaximumreachedUrl,
    imageNoArticle, imageLoadingUrl, imageMoney, imageDontKnow,
    imageDeepBreath, imageBobCrying, imagePrintingLabel, image404,
    imageShipping } from "../../config/constants"

import { BEEP_ENABLED } from "../../config/constants"

import { soundErrorUrl } from "./../../config/constants"
import { soundBeepUrl } from "./../../config/constants"
import { soundCongratzUrl } from "./../../config/constants"


const initState = {
    // True by default, except when explicitly is false
    beepEnabled: localStorage.getItem(BEEP_ENABLED) === 'true',

    soundError: new Audio(soundErrorUrl),
    soundCongratz: new Audio(soundCongratzUrl),
    soundBeep: new Audio(soundBeepUrl),

    errorModal: false,

    textInput: '',
    pressedCtrl: false,

    // 1. scan // 2.weight // 
    state: "scan",

}

const packageAssemblyReducer = (state = initState, action) => {

    function compareSku(a, b){
        if(a.stockCode < b.stockCode) { return -1; }
        if(a.stockCode > b.stockCode) { return 1; }
        return 0
    }

    switch (action.type) {

        case "INPUT_TEXT":
            // EventListener in app.js
            if (action.data === "Control") {
                return {
                    ...state,
                    pressedCtrl: true,
                }
            }

            switch(action.data) {
                // Backspace character
                case "Backspace":
                    return {
                        ...state,
                        textInput: state.textInput.slice(0, -1),
                    }
                
                case "EraseAll":
                    return {
                        ...state,
                        textInput: '',
                    }

                default:
                   // Clean input (only allow)
                    if (action.data &&
                        (action.data.match(/^[a-zA-Z0-9@%#&()/+*§$ß,._\\-]$/) || action.data === "Enter")
                    ) {

                        // IF text changed, refresh
                        return {
                            ...state,
                            textInput: state.textInput + action.data,
                        }
                    }
            }

        case "INPUT_TEXT_REMOVE":
            // EventListener in app.js
            if (action.data === "Control") {
                return {
                    ...state,
                    pressedCtrl: false,
                }
            } else {
                return state
            }

        case "BEEP_ENABLED":
            // Because of weird behaviour, it catches other keys randomly
            if (action.data === true || action.data === false) {

                // save in localStorage
                localStorage.setItem(BEEP_ENABLED, action.data)

                return {
                    ...state,
                    beepEnabled: action.data,
                }
            } else {
                return state
            }
        case "CLEAN":
            return {
                ...initState,
            }

        case "ERROR_MODAL":
            if (action.data) {
                // Modal appear
                state.soundError.play()

                return {
                    ...state,
                    errorModal: true,
                }

            } else {
                // Modal close

                return {
                    ...state,
                    errorModal: false,
                    errorMessage: undefined,
                }
            }

        case "NEW_ORDER":
            return {
                ...state,
                number: action.data.number,
                billbeeOrderNumber: action.data.billbeeOrderNumber,
                billbeeShopId: action.data.billbeeShopId,
                items: [...action.data.items.sort(compareSku)],
                shippingAddress: action.data.shippingAddress,
                sellerComment: action.data.sellerComment,
                comments: action.data.comments,
                errorMessage: undefined,
                miniView: undefined,
                totalWeight: action.data.items.reduce((a, b) => a + (b.weight || 0), 0),
                state: "scan",
            }

        case "ADD_SCANNED_QUANTITY":
            state.beepEnabled && state.soundBeep.play()

            const itemCompleted = action.data.item.scannedQuantity === action.data.newScannedQuantity

            // get item to be edited and change scannedQuantity
            action.data.item.scannedQuantity = action.data.newScannedQuantity

            // filter only items with different code
            var newItems = state.items.filter(item => item.ean !== action.data.item.ean)

            // ADD new item
            newItems.push(action.data.item)

            return {
                ...state,
                // Sort items
                items: [...newItems.sort(compareSku)],
                errorMessage: undefined,
                miniView: action.data.item,
                state: itemCompleted ? state.state : 'scan'
            }


        case "ERROR":
            state.soundError.play()
            function errorCodes(code=action.data.errorCode) {
                switch (code) {
                    case 1:
                        return {
                            ...state,
                            errorMessage: "Bestellung existiert nicht!",
                            miniView: {
                                title: "Bestellung existiert nicht!",
                                image: imageOrderNotFoundUrl,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }

                    case 2:
                        return {
                            ...state,
                            errorMessage: "Maximale Menge erreicht",
                            errorModal: true,
                            miniView: {
                                title: "Maximale Menge erreicht",
                                image: imageMaximumreachedUrl,

                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }

                    case 3:
                        return {
                            ...state,
                            errorMessage: "Artikel existiert nicht in dieser Bestellung",
                            errorModal: true,
                            miniView: {
                                title: "Artikel existiert nicht in dieser Bestellung",
                                image: imageNoArticle,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 4:
                        return {
                            ...state,
                            errorMessage: "Bestellung ist nicht bezahlt",
                            miniView: {
                                title: "Bestellung ist nicht bezahlt",
                                image: imageMoney,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 5:
                        return {
                            ...state,
                            errorMessage: "Bestellung schon versendet?",
                            miniView: {
                                title: "Bestellung schon versendet?",
                                image: imageDontKnow,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 6:
                        return {
                            ...state,
                            errorMessage: "Label existiert bereits",
                            miniView: {
                                title: "Label existiert bereits",
                                image: imageDeepBreath,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 7:
                        return {
                            ...state,
                            errorMessage: action.data.ErrorMessage,
                            miniView: {
                                title: action.data.ErrorMessage,
                                image: imageBobCrying,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 8:
                        return {
                            ...state,
                            errorMessage: "BillbeeId of a product is undefined",
                            miniView: {
                                title: "BillbeeId of a product is undefined",
                                image: imageBobCrying,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 9:
                        return {
                            ...state,
                            errorMessage: "Error code != 200 from backend",
                            miniView: {
                                title: "Error code != 200 from backend",
                                image: image404,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 10:
                        return {
                            ...state,
                            errorMessage: "Can't get list of product_ids",
                            miniView: {
                                title: "Can't get list of product_ids",
                                image: image404,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 11:
                        return {
                            ...state,
                            errorMessage: "Shop different to 51470, 75701, 53339",
                            miniView: {
                                title: "Shop different to 51470, 75701, 53339",
                                image: image404,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 12:
                        return {
                            ...state,
                            errorMessage: "Gewicht ungültig",
                            miniView: {
                                title: "Gewicht ungültig",
                                image: imageDontKnow,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    case 13:
                        return {
                            ...state,
                            errorMessage: "Invalid input",
                            miniView: {
                                title: "Invalid input",
                                image: image404,
                                quantity: "-",
                                scannedQuantity: "-",
                            }
                        }
                    default:
                        return state
                }
            
            }
            
            return errorCodes(action.data.errorCode)

            case "MINI_VIEW":
                function addScannedQuantity(code=action.data.code) {
                    switch (code) {
                        case 1:
                            state.soundCongratz.play()
                            return {
                                ...state,
                                errorMessage: undefined,
                                miniView: {
                                    "title": "Bestellung ist vollständig",
                                    "image": imageCongratulationsUrl,
                                    "quantity": "alles",
                                    "scannedQuantity": "alles"
                                },
                                state: "weight",
                            }

                        case 2:
                            return {
                                ...state,
                                errorMessage: undefined,
                                shippingAddress: undefined,
                                billbeeShopId: undefined,
                                comments: undefined,
                                sellerComment: undefined,
                                miniView: {
                                    "title": "Suche nach Bestellung",
                                    "image": imageLoadingUrl,
                                    "quantity": "...",
                                    "scannedQuantity": "..."
                                },
                                number: undefined,
                                items: undefined,
                            }

                        case 3:
                            return {
                                ...state,
                                errorMessage: undefined,
                                miniView: {
                                    "title": "Versandetikett wird gedruckt...",
                                    "image": imagePrintingLabel,
                                    "quantity": "alles",
                                    "scannedQuantity": "alles"
                                }
                            }

                        case 4:
                            return {
                                ...state,
                                errorMessage: undefined,
                                miniView: {
                                    "title": "Versendet",
                                    "image": imageShipping,
                                    "quantity": "alles",
                                    "scannedQuantity": "alles"
                                },
                                items: undefined,
                                state: "scan",
                            }
                    }
                
                }
                return addScannedQuantity(action.data.code)

        default:
            return state
    }
}

export default packageAssemblyReducer

