import React, { useEffect } from 'react'

import { useDispatch } from "react-redux"

import { useApolloClient } from "@apollo/react-hooks"

import { AUTH_TOKEN, USER_DATA, REMEMBER_ME } from "./../../config/constants"


function AutoLogOut() {

    const client = useApolloClient()

	const dispatch = useDispatch()

    const warnWaiting = 30 * 60 * 1000
    const logoutWaiting = warnWaiting + 30 * 1000

    var warnTimer = undefined
    var logoutTimer = undefined

    function warn() {
        alert("You will be logged out automatically in 1 minute.")
    }

    function logout() {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem(USER_DATA)
        localStorage.removeItem(REMEMBER_ME)
        client.writeData({ data: { isLoggedIn: false, currentUser: {} } })

        // Clear reducer
        dispatch({type: 'CLEAN'})
    }

    function setNewTimeouts() {
        warnTimer = setTimeout(warn, warnWaiting)
        logoutTimer = setTimeout(logout, logoutWaiting)
    }

    function clearLastTimeouts() {
        (warnTimer !== undefined) && clearTimeout(warnTimer);
        (logoutTimer !== undefined) && clearTimeout(logoutTimer);
    }

    function resetTimeout() {
        clearLastTimeouts()
        setNewTimeouts()
    }

    useEffect(() => {

        const events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
          ]

        // Init listeners
        for (var i in events) {
            window.addEventListener(events[i], resetTimeout)
        }

        // setNewTimeouts()

        return () => {
            clearLastTimeouts()

            // End listeners
            for (var i in events) {
                window.removeEventListener(events[i], resetTimeout)
            }
        }
    }, [])


    return(
        <div></div>
    )
}

export default AutoLogOut