import React from 'react'

import { useSelector, useDispatch } from "react-redux"


function Item({ item }) {

    const { pressedCtrl } = useSelector(store => store.packageAssemblyReducer)
    const dispatch = useDispatch()

    function leftClick() {

        let newScannedQuantity = 1

        if (item.scannedQuantity) {
            newScannedQuantity = item.scannedQuantity + 1
        }

        // Check if sum is bigger than maximum
        if (newScannedQuantity > item.quantity) {
            // IF bigger, display error code 2
            dispatch({type: 'ERROR', data: {
                errorCode: 2
            }})

        } else {
            dispatch({type: 'ADD_SCANNED_QUANTITY', data: {
                item,
                newScannedQuantity: pressedCtrl ? item.quantity : newScannedQuantity,
            }})
        }
    }

    function rightClick(event) {
        event.preventDefault()

        let newScannedQuantity = 0

        // !== undefined
        if (item.scannedQuantity) {
            newScannedQuantity = item.scannedQuantity - 1
        }

        // Check if sum is smaller than 0
        if (newScannedQuantity < 0) {
            newScannedQuantity = 0
        }

        dispatch({type: 'ADD_SCANNED_QUANTITY', data: {
            item,
            newScannedQuantity: pressedCtrl ? 0 : newScannedQuantity,
        }})
    }


    return(
        <tr className={item.scannedQuantity === item.quantity ? " table-success" : ""}
            onClick={leftClick} onContextMenu={rightClick} >
            <td className="with-img">
                <img src={`${item.image}`} alt="" className="img-rounded height-30" />
            </td>

            <td className="">
                {`${item.scannedQuantity || 0}/${item.quantity}`}
            </td>

            <td className="">
                {`${item.title}`}
            </td>

            <td className="">
                {`${item.sku}`}
            </td>

            <td className="">
                {`${item.stockCode}`}
            </td>

            <td className="">
                {`${item.ean}`}
            </td>

        </tr>
    )
}

export default Item