import React from 'react'

import { VERSION } from "../../config/constants"

export default function Footer () {

	const year = new Date().getFullYear()

	return (
		<div className="footer" style={{
			"display":"block",
			"margin":"0",
			"fontFamily":"Open Sans, sans-serif",
			"fontSize":".75rem",
			"fontWeight":"900",
			"lineHeight":"1.5",
			"color": "grey",
			"textAlign":"center",
		}}>
			&copy; { year } Aromazeug Alle Rechte vorbehalten. v{VERSION}
		</div>
	)
}