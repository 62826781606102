import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux"

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useApolloClient, useQuery } from "@apollo/react-hooks"

import { AUTH_TOKEN, USER_DATA, REMEMBER_ME } from "./../../config/constants"

// import { CURRENT_USER } from "../../graphql/queries.js"
import { CACHED_CURRENT_USER } from "../../graphql/queries.js"

export default function DropdownProfile() {

	const [dropdownOpen, setDropdownOpen] = useState(false)
	const client = useApolloClient()

    const dispatch = useDispatch()

	// const { data, loading, error } = useQuery(CURRENT_USER, {
	// 	onCompleted: data => {
	// 		client.writeData({ data: { currentUser: data.currentUser } })
	// 		localStorage.setItem(USER_DATA, data.currentUser)
	// 	}
	// })

	const { data, loading, error } = useQuery(CACHED_CURRENT_USER)

	if (error) return("Error.. check dropdown-profile.js")
	if (loading) return("...")
	if (data === undefined | data === null) return("UNDEFINED DATA") // debug


	return(
		<Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(prevState => !prevState)} className="dropdown navbar-user" tag="li">
			<DropdownToggle tag="a">
				{/* <img src={`https://c.tenor.com/jrGh1WraDjMAAAAj/smiley-smile.gif`} alt="" /> */}
				<span className="d-none d-md-inline">{`${data.currentUser.firstName} ${data.currentUser.lastName}`}</span> <b className="caret"></b>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
				{/* <DropdownItem>Perfil</DropdownItem>
				<DropdownItem disabled >Calendar</DropdownItem>
				<DropdownItem>Settings</DropdownItem> */}
				<div className="dropdown-divider"></div>
				<DropdownItem
					onClick={() => {
						localStorage.removeItem(AUTH_TOKEN)
						localStorage.removeItem(USER_DATA)
						localStorage.removeItem(REMEMBER_ME)
						client.writeData({ data: { isLoggedIn: false, currentUser: {} } })

                        // Clear reducer
                        dispatch({type: 'CLEAN'})
					}}
				>Log Out</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	)
}
