import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"

import { FormControl, FormGroup, FormLabel, FormControlLabel } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'


function ConfigBar() {

    const dispatch = useDispatch()

    const [panel, setPanel] = useState(false)
    const { beepEnabled } = useSelector(store => store.packageAssemblyReducer)


	return (
		<div className={`theme-panel theme-panel-lg ${panel ? "active" : ""}`}>
			<a onClick={e => setPanel(!panel)} className="theme-collapse-btn"><i className="fa fa-cog"></i></a>
			
            {/* Beep lever */}
            <FormControl component="fieldset">
                <FormLabel component="legend">Aufbau</FormLabel>
                <FormGroup>
                    <FormControlLabel
                    control={
                        <Switch 
                            checked={beepEnabled}
                            onChange={e => {
                                dispatch({type: 'BEEP_ENABLED', data: !beepEnabled})
                            }}
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    }
                    label="Beep"
                    />
                </FormGroup>
            </FormControl>

		</div>
    )
}

export default ConfigBar