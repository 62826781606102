import React from 'react'
import { useSelector } from "react-redux"
import parse from 'react-html-parser'

// Utils
import { fullCountry, shippingName } from "../utils/functions"
import { BACKURI } from "../../config/constants"

// Components
import Scanner from "./scanner.js"


export default function ScannerManager() {

	const { shippingAddress, number } =
		useSelector(store => store.packageAssemblyReducer)

	const styleCenter = {
		"display":"flex",
		"alignItems":"center"
	}

	const styleText = {
		"alignItems": "left"
	}

    var fixaddressURL = BACKURI + "fixaddress?order=" + number

    return(
        <div>
            
            < Scanner />

			{number && (
				<div className="row m-b-10">
					<h5 className="col" style={styleCenter}>Bestellnummer: {number}</h5>
				</div>
			)}

			{shippingAddress && (
				<div className="row m-b-10">
					<div className="col-md-1"></div>
					<div className="col-md-8">
						<div className="row">
							<span style={styleText}>
								{`${shippingName(shippingAddress)}`}
							</span>
						</div>
						<div className="row">
							<span style={styleText}>{`${shippingAddress.Street} ${shippingAddress.HouseNumber}`}</span>
						</div>
						<div className="row">
							<span style={styleText}>{`${shippingAddress.Zip} ${shippingAddress.City}`}</span>
						</div>
						<div className="row">
							<span style={styleText}>{`${fullCountry(shippingAddress.Country).toUpperCase()}`}</span>
						</div>
                        <div className="row">
                            <span style={styleText}><br></br><a href={`${parse(fixaddressURL)}`} target='_blank'>Adresse Bearbeiten</a></span>
                        </div>
					</div>
					<div className="col-md-3"></div>
				</div>
			)}

        </div>
    )
}

