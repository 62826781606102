import React from 'react'

import { Link } from 'react-router-dom'
import { useQuery } from "@apollo/react-hooks"

// import SearchForm from './search/form.jsx';
import logo from '../../assets/img/logo.png'
import DropdownProfile from './dropdown-profile.js'

import { CACHED_IS_LOGGED_IN } from "../../graphql/queries.js"


function Header () {

	const { data } = useQuery(CACHED_IS_LOGGED_IN)

	if (data && data.isLoggedIn) return(
		<div id="header" className="header navbar-default">
			<div className="navbar-header">
				<Link to="/" className="navbar-brand"><img src={logo} alt="logo"/></Link>
			</div>
			<ul className="navbar-nav navbar-right">
				{/* <SearchForm /> */}
				<DropdownProfile />
				{/* {data && data.isLoggedIn ? <DropdownProfile /> : <LogInButton />} */}
			</ul>
		</div>
	)

	return(
		<div></div>
	)
}

export default Header;
